

// const baseUrl = "aHR0cDovLzQ3LjkyLjIwMC4xNDI6OTA5MC9hcGkv"//47测试服btoa加密   本地用
//  const baseUrl = "aHR0cDovLzE5Mi4xNjguMS4yNjo4OTAwL2FwaS8="//西门子http://192.168.1.26:8900/api/  btoa加密
//  const baseUrl = "aHR0cDovL2xvY2FsaG9zdDo4OTAwL2FwaS8="//西门子http://localhost:8900/api/  btoa加密
//  const baseUrl = "aHR0cHM6Ly9wbWFwZHQtdGVzdC5zaWVtZW5zLWhlYWx0aGluZWVycy5jbi9hcGkv"//西门子测试服btoa加密
const baseUrl="aHR0cHM6Ly9wbWFwZHQuc2llbWVucy1oZWFsdGhpbmVlcnMuY24vYXBpLw=="//西门子正式服域名地址btoa加密
// const baseUrl="aHR0cDovLzE5Mi4xNjguMS43Ojg0ODkvYXBpLw=="//http://192.168.1.7:8489/api/
// const baseUrl="aHR0cDovLzE5Mi4xNjguMS4xOjUzMDAvYXBpLw=="//http://192.168.1.1:5300/api/
// const baseUrl="aHR0cDovL2xvY2FsaG9zdDo1MDAwL2FwaS8="//本地5000btoa加密
// const baseUrl = "aHR0cDovL2xvY2FsaG9zdDo1MzAwL2FwaS8="//西门子测试服btoa加密    http://localhost:5300/api/
// const baseUrl = "aHR0cDovLzE5Mi4xNjguMC4xMzY6NTMwMS9hcGkv"//本地测试服btoa加密    http://192.168.0.136:5301/api/
// const baseUrl = "aHR0cDovLzE5Mi4xNjguMC4xNDY6NTAwMC9hcGkv"//本地测试服btoa加密    http://192.168.0.146:5000/api/

export default baseUrl