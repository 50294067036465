import axios from 'axios';
import baseUrlApi from "./baseUrl.js"
import{ AES } from "../plugins/md5"
var baseUrl;
if (process.env.NODE_ENV == "development") {
    baseUrl =window.atob(baseUrlApi)  
} else {
    baseUrl =window.atob(baseUrlApi)  
}
// 创建axios实例
const instance = axios.create({
    baseURL: baseUrl,
    timeout: 600000
});
// 请求超时从新请求次数，请求间隙
axios.defaults.retry = 3
axios.defaults.retryDelay =1000
// 请求拦截器
instance.interceptors.request.use(function (config) {
    if (localStorage.getItem('Authorization')) {
        console.log('请求拦截器-------')
        let token = AES.decrypt(localStorage.getItem('Authorization'))
        // let token = this.$Base64.decode(localStorage.getItem('Authorization'));
        config.headers.Authorization = token;
        // config.headers.Authorization = localStorage.getItem('Authorization');
    }

    if (config.method.toLowerCase() == 'get') {
        config.params = config.data
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 响应拦截器
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});
export default instance;

